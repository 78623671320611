import {useEffect, useState} from "react";
import {default as ReactSelect} from "react-select";
import {selectCustomStyle} from "../../../styles/selectCustomStyle";
import axios from "axios";
import {store} from "../../../store";
import {changeApprovalPopup, reset_screen_details} from "../../../store/action-creators";
import {useCurrentPopupData} from "../../../hook/useCurrentPopupData";
import {getEnv} from "@urecruits/api";
import {Link, useParams} from "react-router-dom";
import {useTypedSelector} from "../../../hook/useTypedSelector";

const successImage = require("../../../image/icon/success_image.svg");

const token: string = localStorage.getItem("token");
const {API_RECRUITMENT} = getEnv();


const PublishPopup = () => {
	const actionState = useTypedSelector(state => state.screen.actionState);
	const [success, setSuccess] = useState(false);
	const [errorNotification, setErrorNotification] = useState("");
	const [userOption, setUserOption] = useState([]);
  const location = useParams();

	const [userValidate, setUserValidate] = useState(false);
	const [name, setName] = useState({value: "", label: ""});
	const [leadUsersData, setLeadUsersData] = useState([]);

	const data = useCurrentPopupData("approval");

	useEffect(() => {
		axios.get(`${API_RECRUITMENT}/api/company/get-lead-users`, {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		}).then(res => {
			if (res.status == 200) {
				setLeadUsersData(res.data);
				setUserOption(res.data.map(item => {
					return {
						value: `${item.email}`,
						label: `${item.firstname} ${item.lastname}, ${item.email}`,
					};
				}));
			}
		});
	}, []);


	const onPublish = () => {
		//error style trigger
		name.value.length === 0 && (setUserValidate(true));

		if (name.value.length > 0) {
			const approvalItem = leadUsersData.find(x => x.email === name.value)
			actionState === 'Edit Job' && (
        axios.patch(`${API_RECRUITMENT}/api/job`, Object.assign(data, {id: parseInt(location.value), approvalEmail: name.value, approvalName: `${approvalItem.firstname} ${approvalItem.lastname}`}), {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }).then((res) => {
          if (res.status == 200) {
            setSuccess(true);
          }
        })
      )

			actionState === "Create a New Job" && (
				axios.post(`${API_RECRUITMENT}/api/job`, Object.assign(data, {approvalEmail: name.value, approvalName: `${approvalItem.firstname} ${approvalItem.lastname}`}), {
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				}).then((res) => {
					if (res.status == 201) {
						setSuccess(true);
					}
				})
			);

		} else {
			setUserValidate(true);
		}
	};


	return (
		<div className="popup">
			{
				!success ?
					<div className="popup__step">
						<div className="popup__head">
							<p className="popup__head__headline">
								Send for Approval
							</p>
							<svg
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
								className="popup__head__close"
								onClick={() => store.dispatch(changeApprovalPopup(false))}
							>
								<path d="M18 6L12 12M6 18L12 12M12 12L6 6L18 18" stroke="#C1C5CB" strokeWidth="1.5"
								      strokeLinecap="round"
								      strokeLinejoin="round"/>
							</svg>
						</div>
						<div className="popup__body">
							<p className="popup__body__text">
								Are you sure you want to submit this job for approval? This action cannot be undone.
							</p>
							<div className={userValidate ? "popup__body__item error" : "popup__body__item"}>
								<p className="popup__body__label">Name</p>
								<ReactSelect
									options={userOption.length > 0 ? userOption : [{value: "", label: ""}]}
									closeMenuOnSelect={true}
									hideSelectedOptions={false}
									onChange={(currentValue: any) => {
										setName({value: currentValue.value, label: currentValue.label});
										currentValue ? setUserValidate(false) : setUserValidate(true);
									}}
									value={userOption?.find(x => x.label === name.label) ? userOption?.find(x => x.label === name.label) : ""}
									placeholder={`Select name`}
									styles={selectCustomStyle}
									id="name"
									instanceId="name"
								/>
								<p className="error-message">Required field</p>
							</div>
						</div>
						<div className="popup__bottom end">
							<button
								className="popup__bottom__cancel button--empty"
								onClick={() => store.dispatch(changeApprovalPopup(false))}
							>
								Cancel
							</button>
							<button
								className={`popup__bottom__publish button--filled ${name.value.length === 0 ? "button--filled-disable" : ""}`}
								onClick={onPublish}
							>
								Send for Approval
							</button>
							{
								errorNotification.length > 0 && (
									<p className="error-message">{errorNotification}</p>
								)
							}
						</div>
					</div>
					:
					<div className="popup__step">
						<div className="popup__head">
							<svg
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
								className="popup__head__close"
								onClick={() => store.dispatch(changeApprovalPopup(false))}
							>
								<path d="M18 6L12 12M6 18L12 12M12 12L6 6L18 18" stroke="#C1C5CB" strokeWidth="1.5"
								      strokeLinecap="round"
								      strokeLinejoin="round"/>
							</svg>
						</div>
						<div className="popup__body">
							<img src={successImage} alt="" className="popup__body__image"/>
							<p className="popup__body__headline">
								Successfully Sent for Approval
							</p>
						</div>
						<div className="popup__bottom center">
							<Link
								to={"/recruitment/jobs"}
								className="popup__bottom__publish button--filled"
								onClick={() => {
									store.dispatch(changeApprovalPopup(false))
									store.dispatch(reset_screen_details())
								}}
							>
								Got it!
							</Link>
						</div>
					</div>
			}
		</div>
	);
};

export default PublishPopup;