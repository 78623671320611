import '../styles/main.scss'
import { store } from '../store'
import { Provider } from 'react-redux'
import {
  BrowserRouter,
  Routes,
  Route
} from 'react-router-dom'
import { CreateJob, EditJob } from '../components/CreateJob/setDataHoc'
import ApplyInner from '../components/ApplyJob/ApplyInner'
import ViewJobScreen from '../screens/ViewJobScreen'
import { useHasPermission, permissionRoutesType, UnAuthorizePageComponent, NotFoundPageComponent } from '@ucrecruits/globalstyle/src/ucrecruits-globalstyle'


const routes: Array<permissionRoutesType>=[
  {
    path:'job/:value/',
    element:<ViewJobScreen />,
    module:['candidate','recruiter'],
    permissions:'',
  },
  {
    path:'job/create',
    element:<CreateJob />,
    module:'job-post',
    permissions:'add',
  },
  {
    path:'job/:value/edit',
    element:<EditJob />,
    module:'job-post',
    permissions:'edit',
  },
  {
    path:'job/:value/apply',
    element:<ApplyInner/>,
    module:'candidate',
    permissions:'',
  },
]

export default function Root (props) {
  const {onAuthorizedRoutes} = useHasPermission()

  return (
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          {routes.map(route => {
            return <Route path={route.path} key={route.path} element={onAuthorizedRoutes(route.module, route.permissions) ? route.element : <UnAuthorizePageComponent />} />
          })}
          <Route path="job/*" element={<NotFoundPageComponent/>}/>
        </Routes>
      </BrowserRouter>
    </Provider>

  )
}
