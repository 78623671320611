import {store} from "../../../store";
import {
	changeAttachApplicationForm,
	changeCurrenScreen,
	changeJobBoards, setApplicationForm,
} from "../../../store/action-creators";
import {ScreenEnums} from "../../../store/redux-enums";
import {useTypedSelector} from "../../../hook/useTypedSelector";
import {memo, useEffect, useState} from "react";
import axios from "axios";
import BoardItem from "../BoardItem";
import {setApplicationFormData} from "../setDataHoc";

const JobBoards = () => {
	const {attachApplicationFom} = useTypedSelector(state => state.screen);
	const actionState = useTypedSelector(state => state.screen.actionState);
	const popupStatus = useTypedSelector(state => state.screen.draftPopup);
	const initData = useTypedSelector(state => state.screen.jobBoards);
	const {workflow} = useTypedSelector(state => state.screen.aboutCompany);

	const [strapiData, setStrapiData] = useState([]);
	const [showPremium, setShowPremium] = useState(false);
	const [integrations, setIntegrations] = useState(initData.integrations);


	const defaultValues = () => {
		setIntegrations(initData.integrations);
	};


	useEffect(() => {
		defaultValues();
	}, [initData]);

	const moveToPrevStep = () => {
		store.dispatch(changeJobBoards({
			integrations: integrations,
		}));
		store.dispatch(changeCurrenScreen(ScreenEnums.ABOUT_COMPANY));
	};


	const moveToNextStep = () => {
		store.dispatch(changeJobBoards({
			integrations: integrations,
		}));

		store.dispatch(changeCurrenScreen(ScreenEnums.APPLICATION));
	};

	useEffect(() => {
		axios("https://cms-dev.urecruits.com/integrations").then((res) => {
			if (res.status == 200) {
				setStrapiData(res.data);
			}
		});
	}, []);

	useEffect(() => {
		if (actionState === "Edit Job") {
			store.dispatch(changeJobBoards({
				integrations: integrations,
			}));
		}
	}, [integrations]);


	//set data(Application Form) when checkbox true for edit job mode
	useEffect(() => {
		if (actionState === "Edit Job") {
			if (attachApplicationFom) {
				setApplicationFormData();
			} else {
				store.dispatch(setApplicationForm([]));
			}
		}
	}, [attachApplicationFom]);

	//set actually data to redux
	useEffect(() => {
		if (popupStatus) {
			store.dispatch(changeJobBoards({
				integrations: integrations,
			}));
		}
	}, [popupStatus]);

	return (
		<div className="step">
			<div className="step__head">
				<p className="step__head__headline">
					05. Job Boards
				</p>
			</div>
			<div className="step__body">
				<div className="boards">
					<div className="boards__free">
						<p className="boards__description">
							We will post your job on all free job boards available to you. If you don't need something, you can turn
							it
							off by clicking on it.
						</p>
						<p className="boards__title">
							Free Job Boards
						</p>
						<div className="boards__list">
							{
								strapiData?.map((item, index) => {
									if (item.integration_category.name === "Free Job Boards") {
										return (
											<BoardItem
												integrations={integrations}
												setIntegrations={setIntegrations}
												key={index}
												item={item}
											/>
										);
									}
								})
							}
						</div>
						{
							!showPremium && (
								<button
									className="boards__promote button--filled"
									onClick={() => setShowPremium(true)}
								>
									Promote
								</button>
							)
						}
					</div>
					{
						showPremium && (
							<div className="boards__premium">
								<div className="boards__premium__head">
									<p className="boards__title">
										Premium Job Boards
									</p>
									<button
										className="boards__premium__close"
										onClick={() => setShowPremium(false)}
									>
										<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M12 4L8 8M4 12L8 8M8 8L4 4L12 12" stroke="#B0BBCB" strokeWidth="1.5" strokeLinecap="round"
											      strokeLinejoin="round"/>
										</svg>
									</button>
								</div>
								<p className="boards__description">
									Do you want to place on some paid boards of work? Just select the ones you want
								</p>
								<div className="boards__list">
									{
										strapiData?.map((item, index) => {
											if (item.integration_category.name === "Premium Job Boards") {
												return (
													<BoardItem
														integrations={integrations}
														setIntegrations={setIntegrations}
														key={index}
														item={item}
													/>
												);
											}
										})
									}
								</div>
							</div>
						)
					}
				</div>
				<div className="step__row">
					<div className="step__item long">
						<div className="custom-checkbox bottom">
							<input
								type="checkbox"
								className="custom-checkbox__input"
								id="checkbox"
								name="checkbox"
								checked={attachApplicationFom}
								onChange={() => store.dispatch(changeAttachApplicationForm(!attachApplicationFom))}
							/>
							<label htmlFor="checkbox" className="custom-checkbox__label">
								<div className="custom-checkbox__item">
									<span></span>
								</div>
								<p className="custom-checkbox__text">My company is a government contractor or I’d like to capture
									application question, voluntary disclosures, disability.</p>
							</label>
						</div>
					</div>
				</div>
			</div>
			<div className="step__bottom">
				<button
					className="step__bottom__prev"
					onClick={moveToPrevStep}
				>
					Back
				</button>
				{
					attachApplicationFom && (
						<button
							className="step__bottom__next button--empty"
							onClick={moveToNextStep}
						>
							Next
						</button>
					)
				}
			</div>
		</div>
	);
};

export default memo(JobBoards);